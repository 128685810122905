import React from "react";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import Categories from "./Categories";
import { Link } from "react-router-dom";
import { parseUrlFromModel } from "../../helpers/asset";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { onGoingFilesAsyncThunk } from "../../redux/pagesSlices/contentSlice";
import { useDispatch } from "react-redux";
import { ApiRequests } from "../../service/ApiRequests";

const Sidebar = ({ contents }) => {
  const d = useDispatch();
  const handleDeleteOngoing = () => {
    d(
      onGoingFilesAsyncThunk({
        bucketName:
          process.env.NODE_ENV === "production"
            ? "rlibrary"
            : process.env.NODE_ENV === "staging"
            ? "uat-rlibrary"
            : "rplayer-test",
        // bucketName: "rlibrary",
      })
    );
  };

  const handleR2DataSync = () => {
    const response = ApiRequests.syncr2();
    console.log("🚀 ~ handleR2DataSync ~ response:", response);
  };
  return (
    // <div className="Sidebar-lft d-lg-block d-none">
    //   <h5>Top Channels</h5>

    //   {contents &&
    //     contents?.results?.length > 0 &&
    //     contents?.results?.map((item, index) => {

    //       return (
    //         <Link
    //           to={`/player/${item?.id}`}
    //           className="flex-box-two"
    //           key={item?.id}
    //         >
    //           <div>
    //             {item?.thumbnail ? (
    //               <img src={parseUrlFromModel(item?.thumbnail)} alt="" />
    //             ) : (
    //               <PlayCircleIcon />
    //             )}
    //           </div>
    //           <div className="channel-details">
    //             <p className="channel-name">
    //               {item?.name ?? item?.originalName}
    //             </p>
    //             {/* <p className="videos-number">8 Videos</p> */}
    //             {/* <p className="views">
    //             <VisibilityIcon /> 27921 Views
    //           </p> */}
    //           </div>
    //         </Link>
    //       );
    //     })}

    //   {/* <Link to="/channel"  className='flex-box-two'>
    //     <div>
    //       <img src="images/Music.jpg" alt="" />
    //     </div>
    //     <div className='channel-details'>
    //       <p className='channel-name'>TV Channel</p>
    //       <p className='videos-number'>8 Videos</p>
    //       <p className='views'><VisibilityIcon /> 27921 Views</p>
    //     </div>
    //   </Link>

    //   <Link to="/channel"  className='flex-box-two'>
    //     <div>
    //       <img src="images/Music.jpg" alt="" />
    //     </div>
    //     <div className='channel-details'>
    //       <p className='channel-name'>Movie Channel</p>
    //       <p className='videos-number'>8 Videos</p>
    //       <p className='views'><VisibilityIcon /> 3978 Views</p>
    //     </div>
    //   </Link>

    //   <Link to="/channel"  className='flex-box-two'>
    //     <div>
    //       <img src="images/Music.jpg" alt="" />
    //     </div>
    //     <div className='channel-details'>
    //       <p className='channel-name'>Tech Channel</p>
    //       <p className='videos-number'>8 Videos</p>
    //       <p className='views'><VisibilityIcon /> 3278 Views</p>
    //     </div>
    //   </Link> */}

    //   <Categories total={contents?.totalResults} />
    // </div>
    <div style={{ height: "100%" }}>
      <div
        className="d-lg-flex d-none"
        style={{ height: "100%", overflow: "hidden initial", zIndex: "-1" }}
      >
        <CDBSidebar textColor="#fff" backgroundColor="#333">
          <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
            {/* <img src="/images/Logo.png" alt="Logo" className="sidebar-imgs"/> */}
          </CDBSidebarHeader>

          <CDBSidebarContent className="sidebar-content">
            <CDBSidebarMenu>
              <Link exact to="/" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="house">Home</CDBSidebarMenuItem>
              </Link>
              <Link exact to="/youtube" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="video">
                  External Video Library
                </CDBSidebarMenuItem>
              </Link>

              <Link exact to="/admin/upload" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="upload">Upload</CDBSidebarMenuItem>
              </Link>

              <Link exact to="/admin/upload/video" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="upload">Upload segment</CDBSidebarMenuItem>
              </Link>

              <Link exact to="/admin/library" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="table">Library</CDBSidebarMenuItem>
              </Link>

              <Link
                exact
                to="/admin/youtube-library"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="book">
                  Youtube Library
                </CDBSidebarMenuItem>
              </Link>

              <Link exact to="/admin/perfomer" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="microphone">
                  Performers
                </CDBSidebarMenuItem>
              </Link>

              <Link
                exact
                to="/admin/perfomer/list"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="microphone">
                  Performers List
                </CDBSidebarMenuItem>
              </Link>

              <Link exact to="/admin/reports" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="chart-line">
                  Reports
                </CDBSidebarMenuItem>
              </Link>

              <a activeClassName="activeClicked" onClick={handleDeleteOngoing}>
                <CDBSidebarMenuItem icon="trash">
                  Clear CF Cache
                </CDBSidebarMenuItem>
              </a>

              <a activeClassName="activeClicked" onClick={handleR2DataSync}>
                <CDBSidebarMenuItem icon="sync">Sync R2</CDBSidebarMenuItem>
              </a>
            </CDBSidebarMenu>
          </CDBSidebarContent>

          <CDBSidebarFooter style={{ textAlign: "center" }}>
            <div
              style={{
                padding: "20px 5px",
              }}
            >
              Sidebar Footer
            </div>
          </CDBSidebarFooter>
        </CDBSidebar>
      </div>
    </div>
  );
};

export default Sidebar;
